import { useContext } from "react";
import { propContext } from "../../Context/PropContext";

function ErrorProp() {
    const {setErrorProp} = useContext(propContext);
    function close()
    {
        setErrorProp(false);
    }
    return ( 
        <>
        <div className="filter d-flex ">
            <div className="prop p-5 text-center">

                <h4 >Invalid Input</h4>
                <p> Please enter a valid URL or hostname.</p>
                <div className="col-4 mx-auto my-3">
                <button onClick={close}> OK</button>
                </div>
            </div>

        </div>
        </>
     );
}

export default ErrorProp;