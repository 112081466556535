import { createContext, useEffect, useState } from "react";

export const propContext = createContext(false);

function PropContextProvider({ children }) {
  const [isVisible, setIsVisible] = useState(false);
  const [errorProp, setErrorProp] = useState(false);
  const [sigoutProp, setSigoutProp] = useState(false);

  return (
    <propContext.Provider
      value={{
        isVisible,
        setIsVisible,
        errorProp,
        setErrorProp,
        sigoutProp,
        setSigoutProp,
      }}
    >
      {children}
    </propContext.Provider>
  );
}

export default PropContextProvider;
