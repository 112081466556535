import React, { useContext, useState } from "react";
import "./header.css";
import { useNavigate } from "react-router-dom";
import people from "./people.png";
import { authContext } from "../../Context/AuthContext";
import Propout from "../Propout/Propout";
import { propContext } from "../../Context/PropContext";
import ErrorProp from "../ErrorProp/ErrorProp";
import SignoutProp from "../SignoutProp/SignoutProp";

const Header = () => {
  const [target, setTarget] = useState("");
  const navigate = useNavigate();
  const { islogin } = useContext(authContext);
  const { isVisible, setIsVisible, errorProp, setErrorProp, sigoutProp } =
    useContext(propContext);
  const urlPattern =
    /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/;

  const ipPattern =
    /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  const handleScanClick = () => {
    if (islogin) {
      const trimmedTarget = target.trim();

      if (trimmedTarget) {
        if (ipPattern.test(trimmedTarget)) {
          navigate(`/dashboard/networkscan/${trimmedTarget}`);
        } else if (urlPattern.test(trimmedTarget)) {
          navigate(`/dashboard/webscan/target&=${trimmedTarget}`);
        } else {
          setErrorProp(true);
          setTarget(""); // Clear the input field
        }
      } else {
        // Show error message if input is empty
        setErrorProp(true);
      }
    } else {
      setIsVisible(true);
    }
  };

  const handleAntivirusClick = () => {
    if (islogin) {
      navigate("/uploadfile");
    } else {
      setIsVisible(true);
    }
  };

  return (
    <div className="position-relative">
      {errorProp && <ErrorProp />}
      {isVisible && <Propout />}
      {sigoutProp && <SignoutProp />}
      <div className="header section__padding" id="home">
        <div className="header-content">
          <h1 className="gradient__text">
            Unraveling Vulnerabilities, Empowering Unixty: Your Shield in
            Cyberspace
          </h1>

          <div className="header-content__input">
            <input
              type="text"
              placeholder="Enter URL, IP Address, or Hostname"
              value={target}
              onChange={(e) => setTarget(e.target.value)}
            />
            <button type="button" onClick={handleScanClick}>
              Scan Now
            </button>
          </div>

          <button className="antivirus-button" onClick={handleAntivirusClick}>
            Try Our Antivirus Now
          </button>

          <p>
            Unixty: Unraveling Vulnerabilities, Empowering Defenses. Your
            Digital Guardian in Cyberspace, Ensuring Total Security
          </p>

          <div className="header-content__people">
            <img src={people} alt="people" />
            <p>1,600 people got scanned in the last 24 hours</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
