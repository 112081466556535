import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IoIosWarning } from "react-icons/io";
import './AccountSettingsForm.css';

const AccountSettingsForm = () => {
  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    job_title: '',
    company_domain: '',
  });
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const response = await axios.get('http://localhost:3000/users/me', { headers });
        setUserData(response.data.user);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchUserData();
  }, []);

  const handleDeleteAccount = async () => {
    try {
      const token = localStorage.getItem('token');
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      await axios.delete('http://localhost:3000/users', { headers });
      localStorage.removeItem('token');
      window.location.href = '/'; // Redirect to home page or login page
    } catch (error) {
      console.error('Error deleting account:', error);
    }
  };

  return (
    <div className="account-settings-form">
      <h2>Account Settings</h2>
      <div className="form-row">
        <div className="form-group">
          <label>First Name</label>
          <input type="text" name="first_name" value={userData.first_name} disabled />
        </div>
        <div className="form-group">
          <label>Last Name</label>
          <input type="text" name="last_name" value={userData.last_name} disabled />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <label>Email</label>
          <input type="email" name="email" value={userData.email} disabled />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <label>Job Title</label>
          <input type="text" name="job_title" value={userData.job_title} disabled />
        </div>
        <div className="form-group">
          <label>Company Domain</label>
          <input type="text" name="company_domain" value={userData.company_domain} disabled />
        </div>
      </div>
      <div className="form-actions">
        <button
          type="button"
          className="save-button"
          onClick={() => setShowConfirmation(true)}
        >
          Delete Account
        </button>
      </div>
      {showConfirmation && (
        <div className="confirmation-dialog">
          <IoIosWarning  style={{ color: 'red', fontSize: '40px' }} />
          <p>Are you sure you want to delete your account?</p>
          <div className="confirmation-buttons">
            <button className="confirm-button" onClick={handleDeleteAccount}>
              Yes
            </button>
            <button className="cancel-button" onClick={() => setShowConfirmation(false)}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountSettingsForm;

