import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Sidebar.css';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const response = await axios.get('http://localhost:3000/users/me', { headers });
        setUserData(response.data.user);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchUserData();
  }, []);

  return (
    <div className="sidebar">
      <div className="profile-section">
        <div className="profile-info">
          <h2>{userData.first_name} {userData.last_name}</h2>
        </div>
      </div>
      <nav className="nav-menu">
        <Link to="/profile">Account Settings</Link>
        <Link to="/changepassword">Change Password</Link>
      </nav>
    </div>
  );
};

export default Sidebar;
