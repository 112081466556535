import React, { useContext, useState } from "react";
import { RiMenu3Line, RiCloseLine, RiUserLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import "./navbar.css";
import { authContext } from "../../Context/AuthContext";
import { propContext } from "../../Context/PropContext";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const { islogin } = useContext(authContext);
  const {  setSigoutProp } = useContext(propContext);

  function signout() {
    setSigoutProp(true);
  }

  return (
    <div className="navbar">
      <div className="navbar-links">
        <div className="navbar-links_logo">
          <h1> Unixity </h1>
        </div>
        <div className="navbar-links_container">
          <p>
            <Link to="/">Home</Link>
          </p>
          {islogin && (
            <p>
              <Link to="/dashboard">Scanning</Link>
            </p>
          )}

          <p>
            <Link to="/contect">Conect US</Link>
          </p>
          <p>
            <Link to="/faq">FQ&A</Link>
          </p>
          <p>
            <Link to="/blog">Blog</Link>
          </p>
        </div>
      </div>
      <div className="navbar-sign">
        {islogin ? (
          <>
            <p>
              <button type="button" onClick={signout}>
                Sign out
              </button>
            </p>
            <Link to="/profile">
              <RiUserLine size={27} className="navbar-user-icon" />
            </Link>
          </>
        ) : (
          <>
            <p>
              <Link to="/login">Sign in</Link>
            </p>
            <Link to="/signup">
              <button type="button">Sign up</button>
            </Link>
            {islogin && (
              <Link to="/profile">
                <RiUserLine size={27} className="navbar-user-icon" />
              </Link>
            )}
          </>
        )}
      </div>
      <div className="navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="navbar-menu_container scale-up-center">
            <div className="navbar-menu_container-links">
              <p>
                <Link to="/">Home</Link>
              </p>
              <p>
                <Link to="/dashboard">Scanning</Link>
              </p>
              <p>
                <Link to="/about">About</Link>
              </p>
              <p>
                <Link to="/faq">FQ&A</Link>
              </p>
              <p>
                <Link to="/blog">Blog</Link>
              </p>
            </div>
            <div className="navbar-menu_container-links-sign">
              {islogin ? (
                <>
                  <p>
                    <button type="button" onClick={signout}>
                      Sign out
                    </button>
                  </p>
                  <Link to="/profile">
                    <RiUserLine size={27} className="navbar-user-icon" />
                  </Link>
                </>
              ) : (
                <>
                  <p>
                    <Link to="/login">Sign in</Link>
                  </p>
                  <Link to="/signup">
                    <button type="button">Sign up</button>
                  </Link>
                  <Link to="/profile">
                    <RiUserLine size={27} className="navbar-user-icon" />
                  </Link>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
