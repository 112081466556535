import { Link, useNavigate } from "react-router-dom";
import "./signUp.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import axios from "axios";
const SignUp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [errorMessage,setErrorMessage] =useState("")
  const validation = Yup.object({
    first_name: Yup.string()
      .min(3, "Enter vaild name more than 3 char")
      .max(20, "Enter vaild name less than 20 char")
      .required(" first name is required"),
    last_name: Yup.string()
      .min(3, "Enter vaild name more than 3 char")
      .max(20, "Enter vaild name less than 20 char")
      .required(" Last name is required"),
    email: Yup.string()
      .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Enter vaild  Email Please")
      .required("Email is Required"),
    password: Yup.string()
      .matches(
        /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
        "password must contain at least eight characters at least one number both lower and uppercase letters at least one special characters, #, ?, !."
      )
      .required("password is Required"),
    company_domain: Yup.string()
      .min(3, "Not Vaild")
      .max(20, "Not Vaild")
      .required(" company domain is required"),
    job_title: Yup.string()
      .min(3, "Not Vaild")
      .max(20, "Not Vaild")
      .required("job title is required"),
  });
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      company_domain: "",
      job_title: "",
    },
    validationSchema: validation,
    onSubmit: Submit,
  });

  async function Submit() {
    setErrorMessage("");
    setIsLoading(true);
    try {
      const data = await axios.post(
        "http://localhost:3000/users",
        formik.values
      );
      setIsLoading(false);
      navigate("/login");
    } catch (err) {
      setErrorMessage(err.response.data.error);
      setIsLoading(false);
    }
  }

  return (
    <div className="container sign-up-body w-100 px-5 ">
      <div className="row sign-up-cont ">
        <div className=" col-12  sign-up-right-side ">
          <div className="container sign-up-form ">
            <div className="row sign-up-head">
              <div className="col-12">
                <h3 className="gradient__text fs-1">
                  Join Unixty Scanner Today
                </h3>
                <p className="head-detail">
                  Take the first step towards enhanced digital security by
                  registering with Unixty Scanner. Sign up now to access our
                  comprehensive scanning tools and real-time alerts, ensuring
                  your systems remain secure.
                </p>
              </div>
            </div>
            <div className="row sign-up-head">
              <div className="col">
                <form onSubmit={formik.handleSubmit}>
                  <div className="container-fluid px-lg-5 px-md-2">
                    <div className="row sign-up-field">
                      <div className=" col-12 col-lg-6">
                        <div className="col-12">
                          <h5>
                            First Name<span> *</span>
                          </h5>
                        </div>
                        <div className="col-12">
                          <input
                            type="text"
                            className="name-input"
                            name="first_name"
                            value={formik.values.first_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.errors.first_name &&
                            formik.touched.first_name && (
                              <p className="text-danger fs-6">
                                {formik.errors.first_name}
                              </p>
                            )}
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="col-12">
                          <h5>
                            Last Name<span> *</span>
                          </h5>
                        </div>
                        <div className="col-12">
                          <input
                            type="text"
                            className="name-input"
                            name="last_name"
                            value={formik.values.last_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.errors.last_name &&
                            formik.touched.last_name && (
                              <p className="text-danger fs-6">
                                {formik.errors.last_name}
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="row sign-up-field ">
                      <div className="col-12">
                        <h5>
                          Email <span> *</span>
                        </h5>
                      </div>
                      <div className="col-12  ">
                        <input
                          type="text"
                          className="email-input"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.email && formik.touched.email && (
                          <p className="text-danger fs-6">
                            {formik.errors.email}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="row sign-up-field">
                      <div className="col-12">
                        <h5>
                          Company Domain <span> *</span>
                        </h5>
                      </div>
                      <div className="col-12 sign-up-col-10 ">
                        <input
                          type="text"
                          className="company-input"
                          name="company_domain"
                          value={formik.values.company_domain}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.company_domain &&
                          formik.touched.company_domain && (
                            <p className="text-danger fs-6">
                              {formik.errors.company_domain}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="row sign-up-field">
                      <div className="col-12">
                        <h5>
                          Password <span> *</span>
                        </h5>
                      </div>
                      <div className="col-12 sign-up-col-10 ">
                        <input
                          type="password"
                          className="pass-input"
                          name="password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.password && formik.touched.password && (
                          <p className="text-danger fs-6">
                            {formik.errors.password}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="row sign-up-field">
                      <div className="col-12">
                        <h5>
                          Job Title <span> *</span>
                        </h5>
                      </div>
                      <div className="col-12  ">
                        <input
                          type="text"
                          className="job-input"
                          name="job_title"
                          value={formik.values.job_title}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.job_title &&
                          formik.touched.job_title && (
                            <p className="text-danger fs-6">
                              {formik.errors.job_title}
                            </p>
                          )}
                      </div>
                      {errorMessage&& <p className="my-1 p-2 text-danger text-center fs-6">{errorMessage}</p>}
                    </div>
                    <div className="row sign-up-field px-4 align-items-end">
                      <div className=" col-lg-4 col-8 col-m-6  ms-auto sign-up-btn ">
                        <button
                          type="submit"
                          className="sub-btn col-12"
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <span>
                              <i className="fas fa-spinner fa-spin text-center"></i>
                            </span>
                          ) : (
                            <span> Request a Demo</span>
                          )}
                        </button>
                        <div className="text-center">
                          <span className="fw-lighter">
                            Already have an account?
                          </span>
                          <Link to="/login" className="sign-in">
                            Sign In
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SignUp;
