import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../containers/footer/footer";
import Navbar from "../navbar/navbar";
export default function NetworkScan({ item, host, loading, status }) {
 
  return (
    <div className="container-fluid target-container ">
      <Navbar />
      <div className="row justify-content-between">
        <div className="col-11 target-title">
          {loading && (
            <p className="fs-2 fw-bold text-center">Loading Result...</p>
          )}
          {!loading && (
            <>
              <h1>Scan Details For Target {`<${host}>`}</h1>
            </>
          )}
        </div>

        {!loading && item && item.ports && (
          <div className=" col-11 mx-auto  mt-lg-0 mt-5 target-vuln-detil ">
            <div className="row justify-content-between">
              {item.ports.map((port, index) => (
                <div className="col-12 items pb-0 pt-2 px-2" key={index}>
                  <div className="row justify-content-between fw-bold">
                    <div className="col-lg-2 mx-1 col-4 item">
                      <h3>PortNumber</h3>
                      <p>{port.portNumber}</p>
                    </div>
                    <div className="col-lg-2 mx-1 col-4 item ">
                      <h3>Protocol</h3>
                      <p> {port.protocol}</p>
                    </div>
                    <div className="col-lg-2 mx-lg-3 mx-1 col-4 item">
                      <h3>Service</h3>
                      <p> {port.service}</p>
                    </div>
                    <div className="col-lg-4 mx-lg-3 mx-1 col-4 item">
                      <h3>CVEs</h3>
                     
                      <p className="fs-6 text-info text-decoration-underline cursor-pointer" > CVEs are Exist In this port </p>

                    </div>
                    <div className="col-12 px-5 pb-2 pt-4 cves-details">
                       <div className="d-flex  flex-wrap ">
                      {port.cve && port.cve.length > 0 ? (
                        port.cve.map((cve, index) => (
                          <p key={index} className="cve text-center  me-3 cves-border  px-3 py-2 rounded-3 fw-lighter">
                            {cve}
                          </p>
                        ))
                      ) : (
                        <>
                        <p className="text-center"> Null</p>
                        </>
                      )}
                      
                      </div>

                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {!loading && item && (
          <div className=" col-11 mx-auto my-5    target-content">
            <div className="row">
              <div className="col-2 ">
                <h3>host</h3>
                <p>{host}</p>
              </div>
              <div className="col-2">
                <h3>Status</h3>
                <p>{status}</p>
              </div>
              <div className="col-2">
                <h3>Scan-Type</h3>
                <p>Network Scan</p>
              </div>
              <div className="col-2">
                <h3>Risk-Level</h3>
                {(() => {
                  const totalCVEs = item.ports.reduce(
                    (acc, port) => acc + (port.cve ? port.cve.length : 0),
                    0
                  );

                  if (totalCVEs === 0) {
                    return <p>Safe</p>;
                  } else if (totalCVEs < 4) {
                    return <p>Low</p>;
                  } else if (totalCVEs < 8) {
                    return <p>Medium</p>;
                  } else if (totalCVEs < 10) {
                    return <p>High</p>;
                  } else {
                    return <p>Critical</p>;
                  }
                })()}
              </div>
              <div className="col-4">
                <h3>Date</h3>
                <p>{new Date().toLocaleString()}</p>
              </div>
            </div>
          </div>
        )}
       
        {!loading && <Footer />}
      </div>
    </div>
  );
}
