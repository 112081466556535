import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { propContext } from "../../Context/PropContext";

function Propout() {
  const navigate = useNavigate();

  const { isVisible, setIsVisible } = useContext(propContext);

  function closeProp() {
    setIsVisible(false);
  }

  return (
    <>
      {isVisible && (
        <div className="filter d-flex">
          <div className="prop text-center col-lg-6 col-8 px-2 position-relative">
            <i
              className="fa-solid fa-xmark text-white position-absolute fs-4 icon d-inline-block m-3"
              onClick={closeProp}
            ></i>
            <p className="fs-2 gradient__text text-center mt-3 p-lg-3 pb-0 p-1">
              You must be logged in to initiate a scan. Please log in to
              continue.
            </p>
            <p className="p-lg-3 pt-0 p-1" style={{ color: "lightgray" }}>
              If you don't have an account,{" "}
              <strong>you should log in or sign up</strong> to access this
              feature.
            </p>
            <div className="px-3 py-3 mb-2">
              <button onClick={() => navigate("/login")} className="my-2">
                Sign In
              </button>
              <button onClick={() => navigate("/signup")} className="my-2">
                Sign Up
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Propout;
