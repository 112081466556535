import React from 'react'
import Sidebar from './Sidebar'
import AccountSettingsForm from './AccountSettingsForm'
import './userprof.css'

const Userprof = () => {
  return (
    <div className='Userprof'>

<Sidebar />
<AccountSettingsForm />
    </div>
  )
}

export default Userprof