import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useContext, useState } from "react";
import { authContext } from "../../Context/AuthContext";

const Login = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { setLogin } = useContext(authContext);
  const navigate = useNavigate();
  const validation = Yup.object({
    email: Yup.string()
      .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Enter vaild  Email Please")
      .required("Email is Required"),
    password: Yup.string()
      .matches(
        /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
        "Wrong Password"
      )
      .required("password is Required"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: Submit,
    validationSchema: validation,
  });

  async function Submit() {
    setErrorMessage("");
    setIsLoading(true);
    try {
      const { data } = await axios.post(
        "http://localhost:3000/users/login",
        formik.values
      );
      localStorage.setItem("token", data.token);
      setIsLoading(false);
      setLogin(true);
      navigate("/");
    } catch (err) {
      setErrorMessage("Wrong Email Or Password");
      setIsLoading(false);
    }
  }
  return (
    <div className="container login">
      <div className="row login-container">
        <div className="col-md-6 col-12   right  ">
          <div className="d-flex justify-content-center align-items-center h-100 flex-column">
            <h1 className="fs-1 py-3 gradient__text ">
              Welcom To Unixty WebSite{" "}
            </h1>
            <p
              className=" text-center py-3 fs-6"
              style={{ color: "lightgray" }}
            >
              Safeguard your digital assets with Unixty, your trusted
              vulnerability scanner. Our powerful and user-friendly tool helps
              you identify and address vulnerabilities in real-time, ensuring
              your systems stay secure. Log in now and fortify your digital
              presence.
            </p>
          </div>
        </div>
        <div className=" col left  ">
          <h1 className="login-header"> Login Account</h1>
          <form onSubmit={formik.handleSubmit} className="loginForm">
            <div className="container">
              <div className="row">
                <div className=" col-12  loginInput">
                  <label htmlFor="email"> Email</label>
                  <input
                    type="email"
                    placeholder="example@email.com"
                    name="email"
                    className="email pb-0"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  {formik.errors.email && formik.touched.email && (
                    <p className="text-danger pt-0 pb-2">
                      {formik.errors.email}
                    </p>
                  )}

                  <div className=" col-12 email-cont">
                    <label htmlFor="email"> Password</label>
                    <a href="/#"> forget password?</a>
                  </div>
                  <input
                    type="password"
                    placeholder="Password"
                    className="pass pb-0"
                    name="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                  />
                  {formik.errors.password && formik.touched.password && (
                    <p className="text-danger pt-0 pb-2">
                      {formik.errors.password}
                    </p>
                  )}
                </div>
                <div className=" col-12 checkbox">
                  <input
                    type="checkbox"
                    id="chexbox"
                    name="remember"
                    value=""
                  />
                  <label htmlFor="remember"> Remember me </label>
                  {errorMessage && (
                    <p className="text-danger pt-0 pb-2 text-center">
                      {errorMessage}
                    </p>
                  )}
                </div>
                <div className="col-12">
                  <button
                    type="submit"
                    className="sumbit-btn"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <span>
                        <i className="fas fa-spinner fa-spin"></i>
                      </span>
                    ) : (
                      <span>Login</span>
                    )}
                  </button>
                </div>
                <div className="col-12">
                  <p> Need an account?</p>

                  <Link to="/signUp">Sign Up </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
