import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./containers/home/home";
import Navbar from "./component/navbar/navbar";
import Dashboard from "./component/dashboard/dashboard";
import FQA from "./component/faq/FQ&A";
import Login from "./component/login/Login";
import SignUp from "./component/signUp/SignUp";
import Header from "./containers/header/header";
import Footer from "./containers/footer/footer";
import Features from "./containers/features/features";
import About from "./containers/about/about";
import CTA from "./containers/CTA/CTA";
import Blog from "./containers/blog/Blog";
import FAQ from "./containers/f&q/FAQ";
import Scanns from "./component/dashboard/Scann";
import Target from "./component/dashboard/Target";
import WebScan from "./component/dashboard/webSan";
import BlogPage from "./component/blog/blog";
import Uploadfile from "./component/uploadfile/uploadfile";
import AuthContextProvider from "./Context/AuthContext";
import Userprof from "./component/userprofile/Userprof";
import ChangePass from "./component/userprofile/ChangePass";
import AccountSettingsForm from "./component/userprofile/AccountSettingsForm";
import Cont from "./component/contect/cont";
import PropContextProvider from "./Context/PropContext";

const App = () => {
  return (
    <div className="App">
      <AuthContextProvider>
        <PropContextProvider>
          <Router>
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <div className="gradient__bg">
                      <Navbar />
                      <Header />
                    </div>
                    <Home />
                    <Features />
                    <About />
                    <CTA />
                    <Blog />
                    <FAQ />
                    <Footer />
                  </>
                }
              />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/dashboard/scanns" element={<Scanns />} />
              <Route
                path="/dashboard/networkscan/:host/"
                element={<Target />}
              />
              <Route path="/dashboard/webscan/*" element={<WebScan />} />
              <Route path="/faq" element={<FQA />} />
              <Route path="/contect" element={<Cont />} />
              <Route path="/uploadfile" element={<Uploadfile />} />
              <Route
                path="/login"
                element={
                  <>
                    <Navbar />
                    <Login />
                  </>
                }
              />
              <Route
                path="/signup"
                element={
                  <>
                    <Navbar />
                    <SignUp />
                  </>
                }
              />
              <Route path="/blog/:id" element={<BlogPage />} />
              <Route
                path="/profile"
                element={
                  <>
                    <Navbar />
                    <Userprof />
                  </>
                }
              />
              <Route
                path="/changepassword"
                element={
                  <>
                    <Navbar />
                    <ChangePass />
                  </>
                }
              />
              <Route
                path="/account-settings"
                element={
                  <>
                    <Navbar />
                    <AccountSettingsForm />
                  </>
                }
              />
            </Routes>
          </Router>
        </PropContextProvider>
      </AuthContextProvider>
    </div>
  );
};

export default App;
