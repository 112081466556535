import { useContext } from "react";
import { authContext } from "../../Context/AuthContext";
import { propContext } from "../../Context/PropContext";

function SignoutProp() {
  const { setSigoutProp } = useContext(propContext);
  const { setLogin } = useContext(authContext);
  function signout() {
    window.localStorage.removeItem("token");
    setLogin(false);
    setSigoutProp(false);
  }
  function close() {
    setSigoutProp(false);
  }

  return (
    <>
      <div className="filter d-flex ">
        <div className="prop p-5 text-center col-6">
          <h4>Are You Sure Sigout</h4>
          <p> You won't be able to revert this!</p>
          <div className="col-12  my-3 d-flex justify-content-between">
            <button className=" me-2 py-2 px-0 " onClick={signout}>
              {" "}
              OK
            </button>
            <button
              style={{ backgroundColor: "#05386b", border: "#05386b" }}
              className="py-2 px-0"
              onClick={close}
            >
              {" "}
              Cancle
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignoutProp;
